// @flow
import React, { Component } from 'react'
import queryString from 'query-string'
import { connect } from 'react-redux'
import { Redirect, Link } from 'react-router-dom'
import type { Match } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import CircularProgress from '@material-ui/core/CircularProgress'
import Shipping from './Shipping'
import Receipt from './Receipt'
import { getOrder } from '../../state/actions'
import { getOrderById } from '../../state'
import { ORDER_CONFIRMATION_STATUS_TEXT } from '../../constants'
import type { Order } from '../../types'
import { purchaseEvent } from '../../analytics/Events'

type Props = {
  match: Match,
  orderId: string,
  getOrder: Function,
  order: Order,
}

const qs = queryString.parse(window.location.search)

class OrderInformation extends Component<Props> {
  componentDidMount() {
    const { orderId } = this.props
    this.props.getOrder(orderId, qs.email)
    purchaseEvent()
  }

  render() {
    const { order, orderId } = this.props
    if (!orderId) {
      return <Redirect to="/" />
    }
    if (order) {
      return (
        <Grid container direction="column" align="center">
          <Grid item xs={12}>
            <Typography variant="h6" align="center">
              Order Confirmation:
            </Typography>
            <Typography variant="body2" align="center">
              {orderId}
            </Typography>
            <br />
            <Typography variant="h6" align="center">
              Order Status:
            </Typography>
            <Typography variant="body2" align="center">
              {ORDER_CONFIRMATION_STATUS_TEXT[order.status]}
            </Typography>
            <br />
            <Typography variant="h6" align="center">
              Amount:
            </Typography>
            <Grid container justify="center">
              <Grid item>
                <Receipt order={order} />
              </Grid>
            </Grid>
            <br />
            <Shipping order={order} />
            <Typography variant="body2" align="center">
              {order.email}
            </Typography>
            <Typography variant="body2" align="center">
              {order.shipping.phone}
            </Typography>
            <br />
          </Grid>
          <Grid item xs={12} md={6}>
            <Link to="/">
              <Button color="primary" fullWidth>
                Return Home
              </Button>
            </Link>
          </Grid>
        </Grid>
      )
    }
    return <CircularProgress color="secondary" style={{ margin: 30 }} />
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    match: {
      params: { orderId },
    },
  } = ownProps
  return {
    orderId,
    order: getOrderById(state, orderId),
  }
}

const mapDispatchToProps = {
  getOrder,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(OrderInformation)
