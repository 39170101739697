// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { calculateDollars } from '../helpers'
import type { Coupon } from '../types'

type Props = {
  originalPrice: number,
  coupon: Coupon,
  classes: Object,
  style: Object,
}

const styles = {
  originalPrice: {
    color: 'initial',
    textDecoration: 'line-through',
    textDecorationColor: 'red',
  },
  salePrice: {
    color: 'red',
  },
}

export default withStyles(styles)(
  ({ classes, originalPrice, coupon, style }: Props) => (
    <Typography variant="subtitle2" className={classes.salePrice} style={style}>
      <span className={classes.originalPrice}>
        ${calculateDollars(originalPrice)}
      </span>{' '}
      {coupon.amount_off &&
        `$${calculateDollars(originalPrice - coupon.amount_off)}`}
      {coupon.percent_off &&
        `$${calculateDollars((originalPrice * (100 - coupon.percent_off)) / 100)}`}
    </Typography>
  )
)
