export const TSHIRT_PRODUCT_ID = 'prod_E9VKf2j8iZPIRd'

export const PRICING = {
  SHIPPING: 500,
  [TSHIRT_PRODUCT_ID]: 2499,
}

export const COMBO_DISCOUNTS = {
  HIS_AND_HERS: 1000,
  THING_1_AND_2: 1000,
}

export const COLOR_WHITE = 'WHITE'
export const COLOR_BLACK = 'BLACK'
export const COLOR_BLUE = 'BLUE'
export const COLOR_GOLD = 'GOLD'
export const COLOR_GRAY = 'GRAY'
export const COLOR_GREEN = 'GREEN'
export const COLOR_ORANGE = 'ORANGE'
export const COLOR_PINK = 'PINK'
export const COLOR_RED = 'RED'
export const COLOR_YELLOW = 'YELLOW'

export const TSHIRT_COLORS = [
  { name: 'White', value: COLOR_WHITE },
  { name: 'Black', value: COLOR_BLACK },
  { name: 'Blue', value: COLOR_BLUE },
  { name: 'Gold', value: COLOR_GOLD },
  { name: 'Gray', value: COLOR_GRAY },
  { name: 'Green', value: COLOR_GREEN },
  { name: 'Orange', value: COLOR_ORANGE },
  { name: 'Pink', value: COLOR_PINK },
  { name: 'Red', value: COLOR_RED },
  { name: 'Yellow', value: COLOR_YELLOW },
]

export const COLOR_HEX_WHITE = '#FFFFFF'
export const COLOR_HEX_BLACK = '#212121'
export const COLOR_HEX_BLUE = '#164D92'
export const COLOR_HEX_GOLD = '#FGA143'
export const COLOR_HEX_GRAY = '#5F5E5D'
export const COLOR_HEX_GREEN = '#699A56'
export const COLOR_HEX_ORANGE = '#BE5C26'
export const COLOR_HEX_PINK = '#F5C7Cf'
export const COLOR_HEX_RED = '#FF0000'
export const COLOR_HEX_YELLOW = '#F4DE60'

export const BOGO_BG_COLORS = [
  { name: 'Red', value: COLOR_HEX_RED },
  { name: 'White', value: COLOR_HEX_WHITE },
  { name: 'Black', value: COLOR_HEX_BLACK },
  { name: 'Blue', value: COLOR_HEX_BLUE },
  { name: 'Gold', value: COLOR_HEX_GOLD },
  { name: 'Gray', value: COLOR_HEX_GRAY },
  { name: 'Green', value: COLOR_HEX_GREEN },
  { name: 'Orange', value: COLOR_HEX_ORANGE },
  { name: 'Pink', value: COLOR_HEX_PINK },
  { name: 'Yellow', value: COLOR_HEX_YELLOW },
]

export const BOGO_TEXT_COLORS = [
  { name: 'White', value: COLOR_HEX_WHITE },
  { name: 'Black', value: COLOR_HEX_BLACK },
  { name: 'Blue', value: COLOR_HEX_BLUE },
  { name: 'Gold', value: COLOR_HEX_GOLD },
  { name: 'Gray', value: COLOR_HEX_GRAY },
  { name: 'Green', value: COLOR_HEX_GREEN },
  { name: 'Orange', value: COLOR_HEX_ORANGE },
  { name: 'Pink', value: COLOR_HEX_PINK },
  { name: 'Red', value: COLOR_HEX_RED },
  { name: 'Yellow', value: COLOR_HEX_YELLOW },
]

export const SIZES = [
  { name: 'S', value: 'S' },
  { name: 'M', value: 'M' },
  { name: 'L', value: 'L' },
  { name: 'XL', value: 'XL' },
]

export const STATES = {
  AL: 'AL - Alabama',
  AK: 'AK - Alaska',
  AZ: 'AZ - Arizona',
  AR: 'AR - Arkansas',
  CA: 'CA - California',
  CO: 'CO - Colorado',
  CT: 'CT - Connecticut',
  DE: 'DE - Delaware',
  DC: 'DC - District Of Columbia',
  FL: 'FL - Florida',
  GA: 'GA - Georgia',
  HI: 'HI - Hawaii',
  ID: 'ID - Idaho',
  IL: 'IL - Illinois',
  IN: 'IN - Indiana',
  IA: 'IA - Iowa',
  KS: 'KS - Kansas',
  KY: 'KY - Kentucky',
  LA: 'LA - Louisiana',
  ME: 'ME - Maine',
  MD: 'MD - Maryland',
  MA: 'MA - Massachusetts',
  MI: 'MI - Michigan',
  MN: 'MN - Minnesota',
  MS: 'MS - Mississippi',
  MO: 'MO - Missouri',
  MT: 'MT - Montana',
  NE: 'NE - Nebraska',
  NV: 'NV - Nevada',
  NH: 'NH - New Hampshire',
  NJ: 'NJ - New Jersey',
  NM: 'NM - New Mexico',
  NY: 'NY - New York',
  NC: 'NC - North Carolina',
  ND: 'ND - North Dakota',
  OH: 'OH - Ohio',
  OK: 'OK - Oklahoma',
  OR: 'OR - Oregon',
  PA: 'PA - Pennsylvania',
  RI: 'RI - Rhode Island',
  SC: 'SC - South Carolina',
  SD: 'SD - South Dakota',
  TN: 'TN - Tennessee',
  TX: 'TX - Texas',
  UT: 'UT - Utah',
  VT: 'VT - Vermont',
  VA: 'VA - Virginia',
  WA: 'WA - Washington',
  WV: 'WV - West Virginia',
  WI: 'WI - Wisconsin',
  WY: 'WY - Wyoming',
}

export const ORDER_CONFIRMATION_STATUS_TEXT = {
  created:
    'Your order has been created but payment has not been received. Please contact us for more information',
  paid:
    'Your order has been received and is processing. You will receive an email when the order has been shipped.',
  fulfilled:
    'Your order has been shipped. You should receive it within 7-10 business days.',
  canceled: 'Your order has been canceled.',
}

const NODE_ENV = process.env.NODE_ENV

const API_STAGE = process.env.REACT_APP_API_STAGE

export const ORDERS_SERVICE_PATH = ((env, stage) => {
  if (env === 'production') {
    switch (stage) {
      case 'production':
        return `https://saxt7qxdu0.execute-api.us-east-1.amazonaws.com/${stage}`
      case 'dev':
      default:
        return `https://prstgjp2v9.execute-api.us-east-1.amazonaws.com/${stage}`
    }
  }
  return 'http://localhost:4000'
})(NODE_ENV, API_STAGE)

export const COUPONS_SERVICE_PATH = ((env, stage) => {
  if (env === 'production') {
    switch (stage) {
      case 'production':
        return `https://pj8rsz9vih.execute-api.us-east-1.amazonaws.com/${stage}`
      case 'dev':
      default:
        return `https://thrlgbh3b4.execute-api.us-east-1.amazonaws.com/${stage}`
    }
  }
  return 'http://localhost:4000'
})(NODE_ENV, API_STAGE)

export const STRIPE_PUBLISHABLE_KEY = (stage => {
  if (stage === 'production') {
    return 'pk_live_0p8m00sBYe40vchIEizEKg24'
  }
  return 'pk_test_zxpxfln3ZYTk9flopwdox2ii'
})(API_STAGE)

export const GOOGLE_ANALYTICS_ID = 'UA-143420024-01'
