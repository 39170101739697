// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const styles = {
  question: {
    fontWeight: 600,
  },
}

type Props = {
  classes: Object,
}

export default withStyles(styles)(({ classes }: Props) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="h5" align="center">
        Contact
      </Typography>
      <br />
      <Typography variant="body1" align="center">
        <a href="mailto:buildabogo@gmail.com">buildabogo@gmail.com</a>
      </Typography>
    </Grid>
  </Grid>
))
