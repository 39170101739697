// @flow
import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Shipping from './Shipping'
import Payment from './Payment'

const Checkout = () => (
  <Switch>
    <Route path="/checkout/payment" component={Payment} />
    <Route path="/checkout" component={Shipping} />
  </Switch>
)

export default Checkout
