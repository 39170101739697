import React from 'react'
import Typography from '@material-ui/core/Typography'

export default ({ order }) => {
  const {
    shipping: {
      address: { line1, line2, city, postal_code, state },
      name,
    },
  } = order
  return (
    <>
      <Typography variant="h6">Shipping Information:</Typography>
      <Typography variant="body2">{name}</Typography>
      <Typography variant="body2">{line1}</Typography>
      <Typography variant="body2">{line2}</Typography>
      <Typography variant="body2">
        {city}, {state} {postal_code}
      </Typography>
    </>
  )
}
