import _ from 'lodash/fp'
import { createSelector } from 'reselect'
import { combineReducers } from 'redux'
import { PRICING } from '../constants'

const defaultState = {
  activeCoupon: {},
  activeOrder: {},
  orders: {},
  cart: {},
  shippingInformation: {
    state: 'NY',
  },
}

const activeOrder = (state = defaultState.activeOrder, action) => {
  switch (action.type) {
    case 'CLEAR_ACTIVE_ORDER':
      return defaultState.activeOrder
    case 'CREATE_ORDER_PENDING':
      return {
        status: 'PENDING',
      }
    case 'CREATE_PAYMENT_PENDING':
      return {
        ...state,
        status: 'PROCESSING',
      }
    case 'CREATE_PAYMENT_SUCCESS':
      return {
        ...state,
        status: 'PAID',
      }
    case 'CREATE_ORDER_SUCCESS':
      return {
        id: action.data.order.id,
        status: 'CLEAN',
      }
    case 'CREATE_PAYMENT_FAILED':
    case 'CREATE_ORDER_FAILED':
      return {
        ...state,
        status: 'FAILED',
      }
    default:
      return state
  }
}

const activeCoupon = (state = defaultState.activeCoupon, action) => {
  switch (action.type) {
    case 'CLEAR_ACTIVE_COUPON':
    case 'CREATE_PAYMENT_SUCCESS':
      return defaultState.activeCoupon
    case 'GET_COUPON_SUCCESS':
      return action.data.coupon
    default:
      return state
  }
}

const orders = (state = defaultState.orders, action) => {
  switch (action.type) {
    case 'CREATE_ORDER_SUCCESS':
    case 'UPDATE_ORDER_SUCCESS':
    case 'GET_ORDER_SUCCESS':
    case 'CREATE_PAYMENT_SUCCESS':
      const { id } = action.data.order
      return {
        ...state,
        [id]: action.data.order,
      }
    default:
      return state
  }
}

const shippingInformation = (
  state = defaultState.shippingInformation,
  action
) => {
  switch (action.type) {
    case 'SET_SHIPPING_INFORMATION':
      return action.shippingInformation
    case 'CREATE_PAYMENT_SUCCESS':
      return defaultState.shippingInformation
    default:
      return state
  }
}

const cart = (state = defaultState.cart, action) => {
  switch (action.type) {
    case 'ADD_ITEM_TO_CART':
      let quantity = 1
      if (state[action.id]) {
        quantity += state[action.id].quantity
      }
      return {
        ...state,
        [action.id]: {
          item: action.item,
          productId: action.productId,
          quantity,
        },
      }
    case 'SET_QUANTITY_OF_ITEM':
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          quantity: action.quantity,
        },
      }
    case 'REMOVE_ALL_OF_ITEM_FROM_CART':
      const { [action.id]: value, ...remainingItems } = state
      return remainingItems
    case 'CREATE_PAYMENT_SUCCESS':
      return defaultState.cart
    default:
      return state
  }
}

export const getCart = state => state.cart
export const getItems = createSelector(getCart, cart =>
  _.flow(
    _.keys,
    _.map(id => ({ ...cart[id], id }))
  )(cart)
)
export const getNumberOfItemsInCart = createSelector(getItems, items =>
  _.reduce((memo, item) => memo + item.quantity, 0)(items)
)
export const getSubtotalForItems = createSelector(getItems, items =>
  _.reduce((memo, item) => memo + PRICING[item.productId] * item.quantity, 0)(
    items
  )
)

export const getShippingInformation = state => state.shippingInformation
export const getIsValidShippingInformation = createSelector(
  getShippingInformation,
  ({ email, name, phone, address1, city, state, zip }) =>
    email && name && phone && address1 && city && state && zip
)

export const getOrders = state => state.orders
export const getOrderById = (state, orderId) => state.orders[orderId]
export const getOrderIds = createSelector(getOrders, orders =>
  _.flow([_.sortBy('created'), _.map('id')])(orders)
)

export const getActiveOrder = state => state.activeOrder
export const getActiveCoupon = state => state.activeCoupon

export const getHasValidHisAndHersCombo = createSelector(
  getItems,
  _.flow([
    _.filter(
      ({ item: { bogo } }) => bogo.text === 'His' || bogo.text === 'Hers'
    ),
    items => items.length >= 2,
  ])
)
export const getHasValidThing1And2Combo = createSelector(
  getItems,
  _.flow([
    _.filter(
      ({ item: { bogo } }) => bogo.text === 'Thing 1' || bogo.text === 'Thing 2'
    ),
    items => items.length >= 2,
  ])
)

export default combineReducers({
  activeOrder,
  activeCoupon,
  orders,
  cart,
  shippingInformation,
})
