// @flow
import React, { Component } from 'react'
import type { History } from 'react-router-dom'
import { Link } from 'react-router-dom'
import Typography from '@material-ui/core/Typography'
import { connect } from 'react-redux'
import { getSubtotalForItems } from '../../state'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import PaymentButtons from './PaymentButtons'
import { createOrder, createPayment } from '../../state/actions'
import {
  getShippingInformation,
  getIsValidShippingInformation,
  getOrderById,
  getActiveCoupon,
  getActiveOrder,
} from '../../state'
import { FaEdit } from 'react-icons/fa'
import type { Order, Coupon } from '../../types'
import Shipping from '../Order/Shipping'
import Receipt from '../Order/Receipt'
import { confirmOrderEvent } from '../../analytics/Events'

type Props = {
  orderStatus: string,
  order: Order,
  coupon: Coupon,
  isValidShippingInformation: boolean,
  shippingInformation: Object,
  subtotal: number,
  classes: Object,
  history: History,
  createPayment: Function,
  createOrder: Function,
}

const styles = {
  promotion: {
    fontWeight: 600,
    color: 'red',
  },
  editShippingInfo: {
    position: 'absolute',
    top: '15px',
    right: '15px',
    color: '#6E6E6E',
  },
  shippingInfo: {
    position: 'relative',
    backgroundColor: '#EEE',
    padding: 15,
    width: '100%',
    textAlign: 'center',
  },
  spinner: {
    margin: 30,
  },
}

class Payment extends Component<Props> {
  componentDidMount() {
    this.props.createOrder()
    confirmOrderEvent()
  }

  componentDidUpdate(prevProps) {
    const {
      order,
      orderStatus,
      history,
      isValidShippingInformation,
    } = this.props
    if (orderStatus === 'PAID') {
      history.push(
        `/orders/${order.id}?email=${encodeURIComponent(order.email)}`
      )
      return
    } else if (!isValidShippingInformation) {
      history.replace('/checkout')
      return
    }
  }

  onReceivedStripeToken = (token, args) => {
    this.props.createPayment(token)
  }

  render() {
    const { orderStatus, order, shippingInformation, classes } = this.props
    if (orderStatus === 'FAILED') {
      return (
        <>
          <Typography variant="h6" align="center">
            Unfortunately, we couldn't process your order...
          </Typography>
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                <Link to="/">
                  <Button color="primary" fullWidth>
                    Please try again.
                  </Button>
                </Link>
              </Typography>
              <Typography variant="body1" align="center">
                Still having trouble? Contact us at{' '}
                <a href="mailto:contact@buildabogo.com">
                  contact@buildabogo.com
                </a>
              </Typography>
            </Grid>
          </Grid>
        </>
      )
    }

    if (orderStatus === 'PENDING' || orderStatus === 'PROCESSING') {
      return (
        <>
          {orderStatus === 'PENDING' && (
            <Typography variant="h6" align="center">
              Creating your order...
            </Typography>
          )}
          {orderStatus === 'PROCESSING' && (
            <Typography variant="h6" align="center">
              Placing your order...
            </Typography>
          )}
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
          >
            <CircularProgress color="secondary" className={classes.spinner} />
            <Grid item xs={12}>
              <Typography variant="body1" align="center">
                Please do not leave this page.
              </Typography>
            </Grid>
          </Grid>
        </>
      )
    }

    if (order) {
      return (
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <Typography variant="h6" align="center">
              Finish and Pay
            </Typography>
            <br />
            <Receipt order={order} />
          </Grid>
          <br />
          <Grid item md={8} sm={12} className={classes.shippingInfo}>
            <Link to="/checkout/shipping" className={classes.editShippingInfo}>
              <FaEdit size="1.4em" />
            </Link>
            <Shipping order={order} />
          </Grid>
          <br />
          <Typography variant="body2">
            By completing this order, you agree to the{' '}
            <a target="_blank" rel="noopener noreferrer" href="/terms">
              Terms of Service
            </a>
            .
          </Typography>
          <PaymentButtons
            email={shippingInformation.email}
            amount={order.amount}
            onReceivedStripeToken={this.onReceivedStripeToken}
          />
        </Grid>
      )
    }
    return null
  }
}

const mapStateToProps = state => {
  const activeOrder = getActiveOrder(state)
  return {
    shippingInformation: getShippingInformation(state),
    isValidShippingInformation: getIsValidShippingInformation(state),
    subtotal: getSubtotalForItems(state),
    coupon: getActiveCoupon(state),
    orderStatus: activeOrder.status,
    order: getOrderById(state, activeOrder.id),
  }
}

const mapDispatchToProps = {
  createPayment,
  createOrder,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Payment))
