import { ReactGA } from './'

export const addToCartEvent = () => {
  ReactGA.event({
    category: 'ecommerce',
    action: 'Added To Cart',
  })
}

export const shareEvent = () => {
  ReactGA.event({
    category: 'engagement',
    action: 'Share',
  })
}

export const viewCartEvent = () => {
  ReactGA.event({
    category: 'ecommerce',
    action: 'View Cart',
  })
}

export const removeFromCartEvent = () => {
  ReactGA.event({
    category: 'ecommerce',
    action: 'Remove From Cart',
  })
}

export const beginCheckoutEvent = () => {
  ReactGA.event({
    category: 'ecommerce',
    action: 'Begin Checkout',
  })
}

export const confirmOrderEvent = () => {
  ReactGA.event({
    category: 'ecommerce',
    action: 'Confirm Order',
  })
}

export const purchaseEvent = () => {
  ReactGA.event({
    category: 'ecommerce',
    action: 'Purchase',
  })
}
