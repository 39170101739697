export default {
  typography: {
    fontFamily: ['Courier New'],
    useNextVariants: true,
  },
  palette: {
    primary: {
      light: 'FF3433',
      main: '#FF0200',
      dark: '#B20100',
      contrastText: '#FFFFFF',
    },
    secondary: {
      light: '#333333',
      main: '#000000',
      dark: '#000000',
      contrastText: '#FFFFFF',
    },
  },
  overrides: {
    MuiTypography: {
      subtitle2: {
        fontWeight: 'bold',
      },
    },
    MuiButton: {
      contained: {
        boxShadow: 'none',
        borderRadius: '0px',
        paddingBottom: '5px',
        marginBottom: '8px',
      },
      sizeSmall: {
        padding: '5px 8px',
        minHeight: '24px',
      },
    },
  },
}
