// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

const styles = {
  footer: {
    marginTop: 100,
  },
  disclaimer: {
    marginTop: 20,
  },
  links: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'space-between',
  },
  link: {
    color: 'black',
  },
}

type Props = {
  classes: Object,
}

export default withStyles(styles)(({ classes }: Props) => (
  <Grid
    className={classes.footer}
    container
    direction="row-reverse"
    justify="space-between"
  >
    <Grid className={classes.links} item md={4} xs={12}>
      <Typography variant="body2">
        <a
          className={classes.link}
          href="https://instagram.com/buildabogo"
          target="_blank"
          rel="noopener noreferrer"
        >
          Instagram
        </a>
      </Typography>
      <Typography variant="body2">
        <Link className={classes.link} to="/faq">
          FAQ
        </Link>
      </Typography>
      <Typography variant="body2">
        <Link className={classes.link} to="/contact">
          Contact
        </Link>
      </Typography>
    </Grid>
    <Grid className={classes.disclaimer} item md={6} xs={12}>
      <Typography variant="body2">
        Build-a-Bogo is not affiliated with Supreme.
      </Typography>
    </Grid>
  </Grid>
))
