import WebFont from 'webfontloader'

export default new Promise((resolve, reject) => {
  const WebFontConfig = {
    typekit: {
      id: 'ndv6bue',
    },
    active: resolve,
    inactive: reject,
    timeout: 5000,
  }

  WebFont.load(WebFontConfig)
})
