// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash/fp'
import type { History } from 'react-router-dom'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'
import TextField from '@material-ui/core/TextField'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import Button from '@material-ui/core/Button'
import blue from '@material-ui/core/colors/blue'
import { STATES } from '../../constants'
import theme from '../../theme'
import { setShippingInformation } from '../../state/actions'
import {
  getActiveOrder,
  getNumberOfItemsInCart,
  getShippingInformation,
} from '../../state'
import { beginCheckoutEvent } from '../../analytics/Events'

const EMAIL_REGEX = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
const PHONE_REGEX = /\([0-9]{3}\) [0-9]{3}-[0-9]{4}/

const muiTheme = createMuiTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: blue,
  },
})

const styles = {
  buttonContainer: {
    marginTop: 30,
  },
}

type State = {
  name: string,
  email: string,
  phone: string,
  address1: string,
  address2: string,
  city: string,
  state: string,
  zip: string,
  errors: {
    [key: string]: string,
  },
}

type Props = {
  classes: Object,
  history: History,
  onComplete: Function,
  setShippingInformation: Function,
  shippingInformation: State,
  numItemsInCart: number,
}

// TODO: update to pull shipping information from activeOrder

class Shipping extends Component<Props, State> {
  constructor(props) {
    super(props)
    const { shippingInformation } = this.props
    this.state = {
      ...shippingInformation,
      errors: {},
    }
  }

  onSetName = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      name: e.currentTarget.value,
    })
  }
  onSetEmail = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      email: e.currentTarget.value,
    })
  }
  onSetPhone = (e: SyntheticEvent<HTMLInputElement>) => {
    const value = e.currentTarget.value.replace(/[^0-9]/g, '')
    const { length } = value
    const areaCode = value.substring(0, length >= 3 ? 3 : undefined)
    const firstPart =
      length > 3 ? value.substring(3, length >= 6 ? 6 : undefined) : ''
    const secondPart = length > 6 ? value.substring(6, 10) : ''
    const formattedPhone = `(${areaCode}${
      areaCode.length === 3 ? ') ' : ''
    }${firstPart}${firstPart.length === 3 ? '-' : ''}${secondPart}`
    this.setState({
      phone: formattedPhone,
    })
  }
  onSetAddress1 = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      address1: e.currentTarget.value,
    })
  }
  onSetAddress2 = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      address2: e.currentTarget.value,
    })
  }
  onSetCity = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      city: e.currentTarget.value,
    })
  }
  onSetState = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      state: e.currentTarget.value,
    })
  }
  onSetZip = (e: SyntheticEvent<HTMLInputElement>) => {
    this.setState({
      zip: e.currentTarget.value,
    })
  }

  isEmailValid = () =>
    new Promise(resolve => {
      const { email } = this.state
      const testRegex = EMAIL_REGEX.test(email)
      if (!testRegex) {
        resolve({
          email: 'Email is not valid',
        })
      } else {
        resolve({})
      }
    })

  isPhoneValid = () =>
    new Promise(resolve => {
      const { phone } = this.state
      const testRegex = PHONE_REGEX.test(phone)
      if (!testRegex) {
        resolve({
          phone: 'Phone number is not valid',
        })
      } else {
        resolve({})
      }
    })

  onComplete = async () => {
    const validations = [this.isEmailValid(), this.isPhoneValid()]
    const errors = _.reduce((memo, validation) => {
      return {
        ...memo,
        ...validation,
      }
    }, {})(await Promise.all(validations))
    this.setState(
      {
        // hackish way to validate empty fields
        // TODO: create validations for the rest of these fields
        name: '',
        address1: '',
        city: '',
        state: '',
        zip: '',
        ...this.state,
        errors,
      },
      () => {
        if (this.isValidShippingInformation()) {
          this.props.setShippingInformation(this.state)
          this.props.history.push('/checkout/payment')
        }
      }
    )
  }

  isValidShippingInformation = () => {
    const { address2, errors, ...required } = this.state
    const invalid = _.filter(value => value === '')(required)
    if (_.keys(errors).length > 0 || invalid.length > 0) {
      return false
    }
    return true
  }
  componentDidMount() {
    beginCheckoutEvent()
  }

  render() {
    const {
      name,
      email,
      errors,
      phone,
      address1,
      address2,
      city,
      state,
      zip,
    } = this.state
    const { numItemsInCart, classes } = this.props
    if (numItemsInCart === 0) {
      return <Redirect to="/" />
    }
    return (
      <>
        <Typography variant="h6">Shipping Information</Typography>
        <MuiThemeProvider theme={muiTheme}>
          <Grid container direction="row" spacing={24}>
            <Grid item xs={12}>
              <TextField
                required
                label="Name"
                placeholder="Barbara Kruger"
                fullWidth
                value={name}
                error={name === ''}
                onChange={this.onSetName}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                type="email"
                label="Email"
                placeholder="barbara@gmail.com"
                fullWidth
                value={email}
                error={!!errors.email}
                helperText={errors.email}
                onChange={this.onSetEmail}
                margin="normal"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                required
                label="Phone"
                placeholder="(323) 420-6969"
                fullWidth
                value={phone}
                error={!!errors.phone}
                helperText={errors.phone}
                margin="normal"
                onChange={this.onSetPhone}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <TextField
                required
                label="Address"
                placeholder="185 Berry St"
                fullWidth
                value={address1}
                error={address1 === ''}
                margin="normal"
                onChange={this.onSetAddress1}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                label="Apt/Unit"
                placeholder="#69"
                fullWidth
                margin="normal"
                value={address2}
                onChange={this.onSetAddress2}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                label="City"
                placeholder="San Francisco"
                fullWidth
                value={city}
                error={city === ''}
                margin="normal"
                onChange={this.onSetCity}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                select
                label="State"
                value={state}
                error={state === ''}
                SelectProps={{
                  native: true,
                  MenuProps: {},
                }}
                margin="normal"
                onChange={this.onSetState}
                fullWidth
              >
                {_.flow(
                  _.keys,
                  _.map(key => (
                    <option value={key} key={key}>
                      {STATES[key]}
                    </option>
                  ))
                )(STATES)}
              </TextField>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                required
                type="number"
                label="Zip"
                placeholder="94107"
                fullWidth
                value={zip}
                error={zip === ''}
                margin="normal"
                onChange={this.onSetZip}
              />
            </Grid>
          </Grid>
        </MuiThemeProvider>
        <Grid container className={classes.buttonContainer} justify="center">
          <Grid item xs={12} md={4}>
            <Button
              size="small"
              variant="contained"
              color="primary"
              fullWidth
              onClick={this.onComplete}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }
}

const mapStateToProps = state => ({
  shippingInformation: getShippingInformation(state),
  numItemsInCart: getNumberOfItemsInCart(state),
  order: getActiveOrder(state),
})

const mapDispatchToProps = {
  setShippingInformation,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Shipping))
