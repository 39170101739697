import ReactGA from 'react-ga'
import { createBrowserHistory } from 'history'
import { GOOGLE_ANALYTICS_ID } from '../constants'

const API_STAGE = process.env.REACT_APP_API_STAGE

ReactGA.initialize(GOOGLE_ANALYTICS_ID, {
  cookieDomain: 'auto',
  debug: API_STAGE === 'production' ? false : true,
})

const history = createBrowserHistory()

history.listen(location => {
  ReactGA.set({ page: location.pathname })
  ReactGA.pageview(location.pathname)
})

export { ReactGA, history }
