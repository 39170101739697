// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import Grid from '@material-ui/core/Grid'

const styles = {
  question: {
    fontWeight: 600,
  },
}

type Props = {
  classes: Object,
}

const faq = [
  {
    q: 'Are you affiliated with Supreme?',
    a: 'No',
  },
  {
    q: 'What types of shirts do you print on?',
    a: 'Bella + Canvas 3001. 100% combed and ring-spun cotton. We use Direct to Garment (DTG) Printing.',
  },
  {
    q: 'Will my shirt come out exactly how I designed it?',
    a: 'Yes, it will be printed exactly how it is designed.',
  },
  {
    q: 'How long will it take to receive my order?',
    a:
      'Orders are made on demand. It can take up to 2 weeks to process and receive your order in the mail.',
  },
  {
    q: 'How much is shipping?',
    a: 'Shipping is $5 per order.',
  },
  {
    q: 'What is the return/cancellation policy?',
    a:
      'Because each shirt is made to order, returns cannot be accepted. Once an order is placed, it cannot be cancelled.',
  },
]

export default withStyles(styles)(({ classes }: Props) => (
  <Grid container>
    <Grid item xs={12}>
      <Typography variant="h5" align="center">
        Frequently Asked Questions
      </Typography>
      <br />
      {faq.map(({ q, a }) => (
        <>
          <Typography
            variant="subtitle1"
            align="center"
            className={classes.question}
          >
            {q}
          </Typography>
          <Typography variant="body1" align="center">
            {a}
          </Typography>
          <br />
        </>
      ))}
      <Typography
        variant="subtitle1"
        align="center"
        className={classes.question}
      >
        Where can I find cool designs for shirts the community has made using
        this tool?
      </Typography>
      <Typography variant="body1" align="center">
        We promote a lot of designs from our community and things we make
        ourselves on Instagram -{' '}
        <a href="www.instagram.com/buildabogo">www.instagram.com/buildabogo</a>
      </Typography>
    </Grid>
  </Grid>
))
