import React from 'react'
import ReactDOM from 'react-dom'
import './reset.css'
import './index.css'
import App from './App'
import { Provider } from 'react-redux'
import { applyMiddleware, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import reducers from './state'
import webfontLoader from './services/webfontLoader'
import rootSaga from './sagas'
import * as serviceWorker from './serviceWorker'

import * as storage from 'redux-storage'
import createEngine from 'redux-storage-engine-localstorage'

import createSagaMiddleware from 'redux-saga'

const engine = createEngine('@bogo/local')
const middleware = storage.createMiddleware(engine)

const sagaMiddleware = createSagaMiddleware()

const composeEnhancers = composeWithDevTools({})
const store = createStore(
  storage.reducer(reducers),
  {},
  composeEnhancers(applyMiddleware(middleware, sagaMiddleware))
)

sagaMiddleware.run(rootSaga)

const load = storage.createLoader(engine)

const renderApp = () =>
  ReactDOM.render(
    <Provider store={store}>
      <App loaded={load(store)} />
    </Provider>,
    document.getElementById('root')
  )

webfontLoader.then(renderApp).catch(renderApp)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
