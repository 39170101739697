// @flow
import React, { Component } from 'react'
import queryString from 'query-string'
import { connect } from 'react-redux'
import type { History } from 'react-router-dom'
import type { Item } from '../../types'
import { addItemToCart } from '../../state/actions'
import { getIdForItem } from '../../helpers'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Bogo from './Bogo'
import Form from './Form'

import {
  TSHIRT_COLORS,
  BOGO_BG_COLORS,
  BOGO_TEXT_COLORS,
  SIZES,
  TSHIRT_PRODUCT_ID,
} from '../../constants'

const BogoShirt = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

type Props = {
  title?: string,
  textDisabled?: boolean,
  defaultItem?: Item,
  addItemToCart: Function,
  history: History,
}

type State = Item

const qs = queryString.parse(window.location.search)

class Builder extends Component<Props, State> {
  static defaultProps = {
    title: 'Custom BOGO',
  }
  url = new URL(window.location)

  constructor(props) {
    super(props)
    const text =
      qs.text && qs.text.toLowerCase() !== 'supreme' && qs.text.length <= 17
        ? qs.text
        : ''
    this.state = this.props.defaultItem || {
      bogo: {
        text,
        backgroundColor: qs.bc || BOGO_BG_COLORS[0].value,
        textColor: qs.tc || BOGO_TEXT_COLORS[0].value,
      },
      shirt: {
        color: qs.sc || TSHIRT_COLORS[0].value,
        size: qs.size || SIZES[1].value,
      },
    }
  }

  replaceWindowState = () => {
    window.history.replaceState('', '', this.url)
  }

  onChangeText = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    this.setState({
      bogo: {
        ...this.state.bogo,
        text: value,
      },
    })
    this.url.searchParams.set('text', value)
    this.replaceWindowState()
  }

  onChangeBoxColor = (event: SyntheticEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget
    this.setState({
      bogo: {
        ...this.state.bogo,
        backgroundColor: value,
      },
    })
    this.url.searchParams.set('bc', value)
    this.replaceWindowState()
  }

  onChangeTextColor = (event: SyntheticEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget
    this.setState({
      bogo: {
        ...this.state.bogo,
        textColor: value,
      },
    })
    this.url.searchParams.set('tc', value)
    this.replaceWindowState()
  }

  onChangeShirtColor = (event: SyntheticEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget
    this.setState({
      shirt: {
        ...this.state.shirt,
        color: value,
      },
    })
    this.url.searchParams.set('sc', value)
    this.replaceWindowState()
  }

  onChangeSize = (event: SyntheticEvent<HTMLSelectElement>) => {
    const { value } = event.currentTarget
    this.setState({
      shirt: {
        ...this.state.shirt,
        size: value,
      },
    })
    this.url.searchParams.set('size', value)
    this.replaceWindowState()
  }

  addItemToCart = () => {
    const id = getIdForItem(this.state)
    const productId = TSHIRT_PRODUCT_ID // NOTE: hardcoded for now bc we only have 1 item
    this.props.addItemToCart(id, productId, this.state)
  }

  viewCart = () => {
    const { history } = this.props
    history.push('/cart')
  }

  render() {
    const { title, textDisabled } = this.props
    const {
      bogo: { text, textColor, backgroundColor },
      shirt,
    } = this.state
    return (
      <Grid container alignItems="center" spacing={24}>
        <Grid item xs={12} md={6}>
          <BogoShirt color={shirt.color}>
            <Bogo
              text={text || 'Your Text Here'}
              textColor={textColor}
              shirtColor={shirt.color}
              bgColor={backgroundColor}
            />
          </BogoShirt>
        </Grid>
        <Grid item xs={12} md={6}>
          <Form
            title={title}
            textDisabled={textDisabled}
            url={this.url}
            text={text}
            onChangeText={this.onChangeText}
            textColor={textColor}
            onChangeTextColor={this.onChangeTextColor}
            boxColor={backgroundColor}
            onChangeBoxColor={this.onChangeBoxColor}
            shirtColor={shirt.color}
            onChangeShirtColor={this.onChangeShirtColor}
            size={shirt.size}
            onChangeSize={this.onChangeSize}
            addItemToCart={this.addItemToCart}
            viewCart={this.viewCart}
          />
        </Grid>
      </Grid>
    )
  }
}

const mapDispatchToProps = {
  addItemToCart,
}

export default connect(
  null,
  mapDispatchToProps
)(Builder)
