// @flow
import React from 'react'
import _ from 'lodash/fp'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import type { Order } from '../../types'
import SalePrice from '../../components/SalePrice'
import { calculateDollars } from '../../helpers'

type Props = {
  order: Order,
  classes: { 
    [key: string]: string
  },
}

const styles = {
  promotion: {
    fontWeight: 600,
    color: 'red',
  },
}
export default withStyles(styles)(({ order, classes }: Props) => {
  const shipping = _.find({ type: 'shipping' })(order.items)
  const tax = _.find({ type: 'tax' })(order.items)
  let cost = order.amount - shipping.amount - tax.amount
  const subtotal = cost
  const discount = _.find({ type: 'discount' })(order.items)
  if (discount) {
    cost -= discount.amount
  }

  return (
    <>
      <Typography variant="body2" align="right">
        Cost: ${calculateDollars(cost)}
      </Typography>
      {discount && (
        <Typography variant="body2" align="right">
          <span className={classes.promotion}>
            Promo ({discount.description}
            ): {calculateDollars(discount.amount)}
          </span>
        </Typography>
      )}
      <hr />
      <Typography variant="body2" align="right">
        Subtotal: ${calculateDollars(subtotal)}
      </Typography>
      <Typography variant="body2" align="right">
        {shipping.description}: ${calculateDollars(shipping.amount)}
      </Typography>
      <Typography variant="body2" align="right">
        {tax.description}: ${calculateDollars(tax.amount)}
      </Typography>
      <Typography variant="body2" align="right">
        Total:{' '}
        {discount ? (
          <SalePrice
            style={{
              display: 'inline',
            }}
            originalPrice={order.amount - discount.amount}
            coupon={{
              amount_off: -1 * discount.amount,
            }}
          />
        ) : (
          `$${calculateDollars(order.amount)}`
        )}
      </Typography>
    </>
  )
})
