import { ORDERS_SERVICE_PATH, COUPONS_SERVICE_PATH } from '../constants'
import { select, put, call, all, takeEvery } from 'redux-saga/effects'
import {
  getActiveOrder,
  getActiveCoupon,
  getShippingInformation,
  getSubtotalForItems,
} from '../state'

function* getCoupon(action) {
  try {
    yield put({
      type: 'GET_COUPON_PENDING',
    })
    const coupon = yield call(
      fetch,
      `${COUPONS_SERVICE_PATH}/coupons/${action.couponId}`
    )
    const data = yield call([coupon, 'json'])
    if (data.error) {
      throw new Error(`An error occured: ${data.error}`)
    }

    yield put({
      type: 'GET_COUPON_SUCCESS',
      couponId: action.couponId,
      data,
    })
  } catch (error) {
    yield put({
      type: 'GET_COUPON_FAILED',
      couponId: action.couponId,
      error: error.toString(),
    })
  }
}

function* getOrder(action) {
  try {
    yield put({
      type: 'GET_ORDER_PENDING',
    })
    const response = yield call(
      fetch,
      `${ORDERS_SERVICE_PATH}/orders/${
        action.orderId
      }?email=${encodeURIComponent(action.email)}`
    )

    if (!response.ok) {
      throw new Error(
        `An error occured: ${response.status} - ${response.statusText}`
      )
    }

    const data = yield call([response, 'json'])
    if (data.error) {
      throw new Error(`An error occured: ${data.error}`)
    }

    yield put({
      type: 'GET_ORDER_SUCCESS',
      orderId: action.orderId,
      data,
    })
  } catch (error) {
    yield put({
      type: 'GET_ORDER_FAILED',
      orderId: action.orderId,
      error: error.toString(),
    })
  }
}

function* createPayment(action) {
  try {
    yield put({
      type: 'CREATE_PAYMENT_PENDING',
    })
    const state = yield select()
    const activeOrder = getActiveOrder(state)
    const orderId = activeOrder.id
    const { stripeToken } = action

    const bodyData = {
      stripeTokenId: stripeToken.id,
    }

    const response = yield call(
      fetch,
      `${ORDERS_SERVICE_PATH}/orders/${orderId}/payment`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      }
    )

    if (!response.ok) {
      throw new Error(
        `An error occured: ${response.status} - ${response.statusText}`
      )
    }

    const data = yield call([response, 'json'])
    if (data.error) {
      throw new Error(`An error occured: ${data.error}`)
    }

    yield put({
      type: 'CREATE_PAYMENT_SUCCESS',
      data,
    })
  } catch (error) {
    yield put({
      type: 'CREATE_PAYMENT_FAILED',
      error: error.toString(),
    })
  }
}

function* updateOrder(action) {
  try {
    yield put({
      type: 'CREATE_ORDER_PENDING',
    })
    const state = yield select()
    const order = getActiveOrder(state)
    const coupon = getActiveCoupon(state)
    const shippingInformation = getShippingInformation(state)

    const bodyData = {
      coupon: coupon.id,
      shippingInformation,
    }

    const response = yield call(
      fetch,
      `${ORDERS_SERVICE_PATH}/orders/${order.id}`,
      {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(bodyData),
      }
    )

    if (!response.ok) {
      throw new Error(
        `An error occured: ${response.status} - ${response.statusText}`
      )
    }

    const data = yield response.json()
    if (data.error) {
      throw new Error(`An error occured: ${data.error}`)
    }

    yield put({
      type: 'UPDATE_ORDER_SUCCESS',
      data,
    })
  } catch (error) {
    yield put({
      type: 'UPDATE_ORDER_FAILED',
      error: error.toString(),
    })
  }
}

function* createOrder(action) {
  try {
    yield put({
      type: 'CREATE_ORDER_PENDING',
    })
    const state = yield select()
    const coupon = getActiveCoupon(state)
    const shippingInformation = getShippingInformation(state)
    const subtotal = getSubtotalForItems(state)

    const bodyData = {
      coupon: coupon.id,
      shippingInformation,
      subtotal,
      cart: state.cart,
    }

    const response = yield call(fetch, `${ORDERS_SERVICE_PATH}/orders`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(bodyData),
    })

    if (!response.ok) {
      throw new Error(
        `An error occured: ${response.status} - ${response.statusText}`
      )
    }

    const data = yield response.json()
    if (data.error) {
      throw new Error(`An error occured: ${data.error}`)
    }

    yield put({
      type: 'CREATE_ORDER_SUCCESS',
      data,
    })
  } catch (error) {
    yield put({
      type: 'CREATE_ORDER_FAILED',
      error: error.toString(),
    })
  }
}

function* getCouponSaga() {
  yield takeEvery('GET_COUPON', getCoupon)
}

function* createOrderSaga() {
  yield takeEvery('CREATE_ORDER', createOrder)
}

function* updateOrderSaga() {
  yield takeEvery('UPDATE_ORDER', updateOrder)
}

function* getOrderSaga() {
  yield takeEvery('GET_ORDER', getOrder)
}

function* createPaymentSaga() {
  yield takeEvery('CREATE_PAYMENT', createPayment)
}

export default function* rootSaga() {
  yield all([
    createPaymentSaga(),
    getCouponSaga(),
    updateOrderSaga(),
    createOrderSaga(),
    getOrderSaga(),
  ])
}
