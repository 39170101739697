// @flow
import React, { Component } from 'react'
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
} from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { Router, Route, Switch, Link } from 'react-router-dom'
import Grid from '@material-ui/core/Grid'
import Builder from './features/Builder'
import Footer from './features/Footer'
import Cart from './features/Cart'
import Order from './features/Order'
import Faq from './features/Faq'
import Terms from './features/Terms'
import Contact from './features/Contact'
import Checkout from './features/Checkout'
import theme from './theme'
import { history } from './analytics'

const muiTheme = createMuiTheme(theme)

type Props = {
  loaded: Promise<Object>,
  classes: Object,
}
type State = {
  loaded: boolean,
}

const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
    minHeight: '100vh',
  },
  saleBanner: {
    position: 'absolute',
    backgroundColor: 'red',
    padding: 5,
  },
  saleText: {
    color: '#fff',
  },
  saleBold: {
    fontWeight: 600,
  },
  content: {
    width: '100%',
    flex: 1,
    justifyContent: 'flex-start',
  },
  bogoHeader: {
    fontFamily: 'futura-pt, Helvetica, Arial',
    fontWeight: 600,
    fontStyle: 'italic',
    fontSize: '42px',
    color: '#fff',
    backgroundColor: 'red',
    padding: '5px 10px',
    marginTop: '100px',
    marginBottom: '40px',
    whiteSpace: 'nowrap',
  },
}

class App extends Component<Props, State> {
  state = {
    loaded: false,
  }
  constructor(props: Props) {
    super(props)
    props.loaded.then(() => {
      this.setState({
        loaded: true,
      })
    })
  }
  onClickGalleryItem = i => {
    const {
      bogo: { text, textColor, backgroundColor },
      shirt: { color },
    } = i
    window.location.replace(
      `/?text=${text}&sc=${color}&bc=${encodeURIComponent(
        backgroundColor
      )}&tc=${encodeURIComponent(textColor)}`
    )
  }

  render() {
    const { classes } = this.props
    const { loaded } = this.state
    return (
      <MuiThemeProvider theme={muiTheme}>
        <Router history={history}>
          <>
            {false && (
              <Grid container justify="center" className={classes.saleBanner}>
                <Typography
                  align="center"
                  variant="body2"
                  className={classes.saleText}
                >
                  Get <span className={classes.saleBold}>FREE SHIPPING</span>{' '}
                  with promo code:{' '}
                  <span className={classes.saleBold}>BOGO_FREE_SHIPPING</span>
                </Typography>
              </Grid>
            )}
            <Grid
              container
              alignItems="center"
              direction="column"
              className={classes.container}
            >
              <Link to="/">
                <Typography variant="h1" className={classes.bogoHeader}>
                  Build-a-Bogo
                </Typography>
              </Link>
              <div className={classes.content}>
                <Switch>
                  <Route path="/orders/:orderId" component={Order} />
                  <Route path="/checkout" component={Checkout} />
                  <Route path="/cart" render={() => loaded && <Cart />} />
                  <Route path="/faq" component={Faq} />
                  <Route path="/terms" component={Terms} />
                  <Route path="/contact" component={Contact} />
                  <Route path="/" component={Builder} />
                </Switch>
              </div>
              <Footer />
            </Grid>
          </>
        </Router>
      </MuiThemeProvider>
    )
  }
}

export default withStyles(styles)(App)
