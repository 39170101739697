// @flow
import React, { Component } from 'react'
import styled from 'styled-components'
import {
  renderTee,
  renderBogo,
  getTeeShirtDimensions,
  getBogoDimensions,
  dpr,
} from '../../services/bogo'

const ScaledCanvas = styled.canvas`
  width: 100%;
  max-width: 380px;
`

type Props = {
  text: string,
  textColor: string,
  shirtColor: string,
  bgColor: string,
}

class Bogo extends Component<Props> {
  ref: { current: ?HTMLCanvasElement } = React.createRef()

  componentDidMount() {
    this.renderOnCanvas()
  }

  componentDidUpdate(prevProps: Props) {
    const { text, textColor, bgColor, shirtColor } = this.props
    if (
      //TODO: we can split up the canvas rendering here to only re-render entire thing if text changes, otherwise only re-render box
      prevProps.text !== text ||
      prevProps.textColor !== textColor ||
      prevProps.bgColor !== bgColor ||
      prevProps.shirtColor !== shirtColor
    ) {
      this.renderOnCanvas()
    }
  }

  renderOnCanvas = async () => {
    const { text, bgColor, textColor, shirtColor } = this.props
    const canvas = this.ref.current
    if (canvas) {
      const [width, height] = await getTeeShirtDimensions(shirtColor)
      canvas.width = width * dpr
      canvas.height = height * dpr
      const ctx = canvas.getContext('2d')
      ctx.scale(dpr, dpr)
      await renderTee(ctx, shirtColor)
      const [bogoWidth, bogoHeight] = getBogoDimensions(ctx, text)
      const bogoX = (width - bogoWidth) / 2
      const bogoY = (height - bogoHeight) / 3.5
      await renderBogo(ctx, text, textColor, bgColor, bogoX, bogoY)
    }
  }

  render() {
    return <ScaledCanvas ref={this.ref} />
  }
}

export default Bogo
