// @flow
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { calculateDollars } from '../../helpers'
import { STRIPE_PUBLISHABLE_KEY } from '../../constants'

const styles = {
  buttonContainer: {
    marginTop: 15,
  },
}

type Props = {
  classes: Object,
  onReceivedStripeToken: string => void,
  amount: number,
  email: string,
}

class PaymentButtons extends Component<Props> {
  stripe = window.Stripe(STRIPE_PUBLISHABLE_KEY)

  handler = window.StripeCheckout.configure({
    key: STRIPE_PUBLISHABLE_KEY,
    image: 'https://stripe.com/img/documentation/checkout/marketplace.png',
    locale: 'auto',
    token: this.props.onReceivedStripeToken,
  })

  openHandler = () => {
    const { amount, email } = this.props
    this.handler.open({
      name: 'Build-a-Bogo',
      description: 'Box Logos for everybody',
      zipCode: true,
      amount,
      email,
    })
  }

  initiateStripePaymentRequest = () => {
    const { amount, onReceivedStripeToken } = this.props
    const paymentRequest = this.stripe.paymentRequest({
      country: 'US',
      currency: 'usd',
      total: {
        label: 'Demo total',
        amount,
      },
      requestPayerName: true,
      requestPayerEmail: true,
    })

    paymentRequest.canMakePayment().then(res => {
      const elements = this.stripe.elements()
      const prButton = elements.create('paymentRequestButton', {
        paymentRequest,
      })
      if (res) {
        prButton.mount('#payment-request-button')
      }
    })

    paymentRequest.on('token', e => onReceivedStripeToken(e.token))
  }

  componentDidUpdate(prevProps) {
    const { amount } = this.props
    if (amount !== prevProps.amount) {
      this.initiateStripePaymentRequest()
    }
  }

  componentDidMount() {
    const { amount } = this.props
    if (amount !== 0) {
      this.initiateStripePaymentRequest()
    }
  }

  render() {
    const { classes, amount } = this.props
    return (
      <Grid container className={classes.buttonContainer} justify="center">
        <Grid item xs={12} md={6}>
          <Grid container direction="column">
            <Grid item xs={12}>
              <Button
                size="small"
                variant="contained"
                color="primary"
                fullWidth
                onClick={this.openHandler}
              >
                Pay with Card - ${calculateDollars(amount)}
              </Button>
            </Grid>
            <Grid xs={12} item id="payment-request-button" />
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

export default withStyles(styles)(PaymentButtons)
