// @flow
import React, { Component } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { PRICING, TSHIRT_PRODUCT_ID } from '../../constants'
import { getNumberOfItemsInCart } from '../../state'
import ShareSocial from '../ShareSocial'
import theme from '../../theme'
import { calculateDollars } from '../../helpers'
import { addToCartEvent, shareEvent } from '../../analytics/Events.js'

import {
  SIZES,
  TSHIRT_COLORS,
  BOGO_BG_COLORS,
  BOGO_TEXT_COLORS,
} from '../../constants'

const red = theme.palette.primary.main

const Input = styled.input`
  width: 100%;
  font-family: 'Courier New';
  font-size: 12px;
  padding: 5px;
  margin-bottom: 5px;
  border-color: ${({ error }) => (error ? red : 'initial')};
  &:focus {
    ${({ error }) => error && `outline-color: ${red}`};
  }
`

const Select = styled.select`
  width: 100%;
  font-family: 'Courier New';
  font-size: 14px;
  padding: 5px;
  margin-bottom: 5px;
`

type Props = {
  title?: string,
  url: Object,
  textDisabled?: boolean,
  text: string,
  onChangeText: Function,
  textColor: string,
  onChangeTextColor: Function,
  boxColor: string,
  onChangeBoxColor: Function,
  shirtColor: string,
  onChangeShirtColor: Function,
  size: string,
  onChangeSize: Function,
  addItemToCart: Function,
  numItemsInCart: number,
  viewCart: Function,
}

type State = {
  textError?: string,
  showShareSocialModal: boolean,
}

class Form extends Component<Props, State> {
  state = { textError: '', showShareSocialModal: false }

  showShareSocialModal = () => {
    shareEvent()
    this.setState({
      showShareSocialModal: true,
    })
  }

  hideShareSocialModal = () => {
    this.setState({
      showShareSocialModal: false,
    })
  }

  validateText = (text: string): { error?: ?string, success?: boolean } => {
    if (text.toLowerCase() === 'supreme') {
      return {
        error: 'The word "Supreme" cannot be used.',
      }
    } else if (text.length > 17) {
      return {
        error: 'A maximum of 17 characters is supported.',
      }
    }
    return {
      success: true,
    }
  }

  onChangeText = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    const { error, success } = this.validateText(value)
    if (error) {
      this.setState({
        textError: error,
      })
    } else if (success) {
      this.setState({
        textError: '',
      })
      const { onChangeText } = this.props
      onChangeText(event)
    }
  }

  render() {
    const {
      textDisabled,
      text,
      textColor,
      boxColor,
      shirtColor,
      onChangeTextColor,
      onChangeBoxColor,
      onChangeShirtColor,
      size,
      onChangeSize,
      addItemToCart,
      viewCart,
      numItemsInCart,
      url,
      title,
    } = this.props
    const { showShareSocialModal, textError } = this.state
    return (
      <>
        <Grid container direction="column" alignItems="center">
          <Grid
            container
            direction="column"
            alignItems="flex-start"
            spacing={8}
          >
            <Grid item sm={12}>
              {title && <Typography variant="subtitle2">{title}</Typography>}
              <Typography variant="subtitle2">
                ${calculateDollars(PRICING[TSHIRT_PRODUCT_ID])}
              </Typography>
              <Typography variant="body2">
                Lightweight Fitted Unisex Tee
              </Typography>
            </Grid>
            <Grid item sm={12} md={8}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  {textError && (
                    <Typography variant="body2" style={{ color: 'red' }}>
                      {textError}
                    </Typography>
                  )}
                  {!textDisabled && (
                    <Input
                      error={this.state.textError}
                      type="text"
                      placeholder="Your Text Here"
                      value={text}
                      onChange={this.onChangeText}
                    />
                  )}
                </Grid>
                <Grid item xs={12}>
                  <Select onChange={onChangeShirtColor} value={shirtColor}>
                    {TSHIRT_COLORS.map(({ name, value }, idx) => (
                      <option value={value} key={idx}>
                        Shirt color: {name}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Select onChange={onChangeBoxColor} value={boxColor}>
                    {BOGO_BG_COLORS.map(({ name, value }, idx) => (
                      <option value={value} key={idx}>
                        Box color: {name}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Select onChange={onChangeTextColor} value={textColor}>
                    {BOGO_TEXT_COLORS.map(({ name, value }, idx) => (
                      <option value={value} key={idx}>
                        Text color: {name}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Select onChange={onChangeSize} value={size}>
                    {SIZES.map(({ name, value }, idx) => (
                      <option value={value} key={idx}>
                        Size: {name}
                      </option>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="small"
                    variant="contained"
                    color={text !== '' ? 'primary' : 'disabled'}
                    fullWidth
                    onClick={
                      text !== ''
                        ? () => {
                            addItemToCart()
                            addToCartEvent()
                          }
                        : () => {
                            this.setState({
                              textError: 'Text cannot be blank.',
                            })
                          }
                    }
                  >
                    Add to cart
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    fullWidth
                    onClick={viewCart}
                  >
                    {numItemsInCart > 0 && `[${numItemsInCart}] `}
                    View cart
                  </Button>
                </Grid>
                <Grid item xs={12}>
                  <Button
                    size="small"
                    variant="contained"
                    color={text !== '' ? 'secondary' : 'disabled'}
                    fullWidth
                    onClick={
                      text !== ''
                        ? this.showShareSocialModal
                        : () => {
                            this.setState({
                              textError: 'Text cannot be blank.',
                            })
                          }
                    }
                  >
                    Share with friends
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <ShareSocial
          url={url}
          open={showShareSocialModal}
          onClose={this.hideShareSocialModal}
        />
      </>
    )
  }
}

const mapStateToProps = state => ({
  numItemsInCart: getNumberOfItemsInCart(state),
})

const mapDispatchToProps = {}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Form)
