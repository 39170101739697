// @flow
import React, { Component } from 'react'
import DialogTitle from '@material-ui/core/DialogTitle'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import InputAdornment from '@material-ui/core/InputAdornment'
import ListItemText from '@material-ui/core/ListItemText'
import TextField from '@material-ui/core/TextField'
import { FaLink, FaFacebook, FaTwitter } from 'react-icons/fa'
import { FacebookShareButton, TwitterShareButton } from 'react-share'
import blue from '@material-ui/core/colors/blue'
import theme from '../theme'

type Props = {
  url: { href: string },
  open: boolean,
  onClose: Function,
}

const muiTheme = createMuiTheme({
  ...theme,
  palette: {
    ...theme.palette,
    primary: blue,
  },
})

class ShareSocial extends Component<Props> {
  render() {
    const { url, open, onClose } = this.props
    return (
      <Dialog
        aria-labelledby="Share to Social"
        open={open}
        onClose={onClose}
        maxWidth="md"
      >
        <DialogTitle id="simple-dialog-title">
          Share on Social Media
        </DialogTitle>
        <List>
          <ListItem>
            <MuiThemeProvider theme={muiTheme}>
              <TextField
                label="Shareable Link"
                value={url.href}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <FaLink />
                    </InputAdornment>
                  ),
                  readOnly: true,
                }}
              />
            </MuiThemeProvider>
          </ListItem>
          <FacebookShareButton
            url={url.href}
            quote="I created my own, custom box logo tee shirt! Check it out:"
            hashtag="#buildabogo"
          >
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <FaFacebook />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Share on Facebook</ListItemText>
            </ListItem>
          </FacebookShareButton>
          <TwitterShareButton
            url={url.href}
            title="I created my own, custom box logo tee shirt! Check it out:"
            hashtags={['buildabogo', 'boxlogo', 'streetwear', 'supreme']}
          >
            <ListItem button>
              <ListItemAvatar>
                <Avatar>
                  <FaTwitter />
                </Avatar>
              </ListItemAvatar>
              <ListItemText>Share on Twitter</ListItemText>
            </ListItem>
          </TwitterShareButton>
        </List>
      </Dialog>
    )
  }
}
export default ShareSocial
