// @flow
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import _ from 'lodash/fp'
import { connect } from 'react-redux'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import DeleteForever from '@material-ui/icons/DeleteForever'
import Bogo from './Builder/Bogo'
import {
  clearActiveCoupon,
  getCoupon,
  removeAllOfItemFromCart,
} from '../state/actions'
import {
  getHasValidHisAndHersCombo,
  getHasValidThing1And2Combo,
  getActiveCoupon,
  getSubtotalForItems,
  getItems,
} from '../state'
import {
  PRICING,
  BOGO_BG_COLORS,
  TSHIRT_COLORS,
  BOGO_TEXT_COLORS,
  COMBO_DISCOUNTS,
} from '../constants'
import { calculateDollars } from '../helpers'
import theme from '../theme'
import type { Coupon } from '../types'
import { viewCartEvent, removeFromCartEvent } from '../analytics/Events'

const red = theme.palette.primary.main

const Input = styled.input`
  width: 100%;
  height: 100%;
  font-family: 'Courier New';
  font-size: 12px;
  padding: 5px;
  margin-bottom: 5px;
  border-color: ${({ error }) => (error ? red : 'initial')};
  &:focus {
    ${({ error }) => error && `outline-color: ${red}`};
  }
`

const styles = {
  root: {
    width: '100%',
  },
  section: {
    border: '1px solid  #CCC',
  },
  itemDetails: {
    height: '100%',
  },
  cartDetails: {
    marginTop: 30,
  },
  promotion: {
    fontWeight: 600,
    color: 'red',
  },
  buttons: {
    marginTop: 30,
  },
  deleteIcon: {
    cursor: 'pointer',
    '&:hover': {
      color: 'red',
      animation: 'grow 600ms',
    },
  },
  item: {
    margin: '0',
    width: '100%',
  },
  '@keyframes grow': {
    '50%': {
      transform: 'scale(1.33)',
    },
    '100%': {
      transform: 'scale(1)',
    },
  },
}

type State = {
  couponCode: string,
}

type Props = {
  clearActiveCoupon: Function,
  getCoupon: Function,
  coupon: Coupon,
  subtotal: number,
  items: [],
  classes: Object,
  removeAllOfItemFromCart: Function,
  hasValidHisAndHersCombo: boolean,
  hasValidThing1And2Combo: boolean,
}

class Cart extends Component<Props, State> {
  state = {
    couponCode: '',
  }

  componentDidMount() {
    const { clearActiveCoupon, items } = this.props
    clearActiveCoupon()
    if (items.length) {
      viewCartEvent()
    }
  }

  onApplyCouponCode = () => {
    const { getCoupon } = this.props
    const { couponCode } = this.state
    getCoupon(couponCode)
  }

  onChangeCouponCode = (event: SyntheticInputEvent<HTMLInputElement>) => {
    const { value } = event.currentTarget
    this.setState({
      couponCode: value,
    })
  }

  renderCouponAndCombos() {
    const {
      coupon,
      classes,
      hasValidHisAndHersCombo,
      hasValidThing1And2Combo,
    } = this.props
    if (coupon.id) {
      return (
        <Typography variant="body2" align="right">
          Promo Code <span className={classes.promotion}>({coupon.id})</span>:{' '}
          <span className={classes.promotion}>
            {coupon.amount_off && `-$${calculateDollars(coupon.amount_off)}`}
            {coupon.percent_off && `%${coupon.percent_off} OFF`}
          </span>
        </Typography>
      )
    }
    if (hasValidHisAndHersCombo) {
      return (
        <Typography variant="body2" align="right">
          <span className={classes.promotion}>HIS AND HERS PROMOTION</span>:{' '}
          <span className={classes.promotion}>
            -$
            {calculateDollars(COMBO_DISCOUNTS.HIS_AND_HERS)}
          </span>
        </Typography>
      )
    }
    if (hasValidThing1And2Combo) {
      return (
        <Typography variant="body2" align="right">
          <span className={classes.promotion}>
            THING 1 AND THING 2 PROMOTION
          </span>
          :{' '}
          <span className={classes.promotion}>
            {`-$${calculateDollars(COMBO_DISCOUNTS.HIS_AND_HERS)}`}
          </span>
        </Typography>
      )
    }
  }

  render() {
    const { items, classes, removeAllOfItemFromCart, subtotal } = this.props
    const { couponCode } = this.state
    return (
      <Grid container alignItems="stretch" className={classes.root}>
        {items.map(item => {
          if (item === undefined) {
            return null
          }
          const {
            item: { bogo, shirt },
            id,
            productId,
            quantity,
          } = item
          const textColor = _.flow([
            _.find({ value: bogo.textColor }),
            _.get('name'),
          ])(BOGO_TEXT_COLORS)
          const bgColor = _.flow([
            _.find({ value: bogo.backgroundColor }),
            _.get('name'),
          ])(BOGO_BG_COLORS)
          const shirtColor = _.flow([
            _.find({ value: shirt.color }),
            _.get('name'),
          ])(TSHIRT_COLORS)
          return (
            <Grid key={id} item xs={12}>
              <Grid
                container
                direction="row"
                spacing={24}
                alignItems="stretch"
                className={classes.item}
              >
                <Grid item xs={3} md={2} className={classes.section}>
                  <Bogo
                    text={bogo.text}
                    textColor={bogo.textColor}
                    shirtColor={shirt.color}
                    bgColor={bogo.backgroundColor}
                  />
                </Grid>
                <Grid item xs={9} md={10} className={classes.section}>
                  <Grid
                    container
                    direction="row"
                    className={classes.itemDetails}
                    alignItems="center"
                  >
                    <Grid item xs={11}>
                      <Grid
                        container
                        direction="row"
                        alignItems="center"
                        className={classes.itemDetails}
                      >
                        <Grid item xs={12} md={6}>
                          <Typography variation="body2">
                            Text: {bogo.text}
                          </Typography>
                          <Typography variation="body2">
                            Shirt color: {shirtColor}
                          </Typography>
                          <Typography variation="body2">
                            Box color: {bgColor}
                          </Typography>
                          <Typography variation="body2">
                            Text color: {textColor}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={4}>
                          <Typography variation="body2">
                            Size: {shirt.size}
                          </Typography>
                          <Typography variation="body2">
                            Quantity: {quantity}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md={2}>
                          <Typography variant="subtitle2">
                            ${calculateDollars(PRICING[productId])}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={1}>
                      <DeleteForever
                        className={classes.deleteIcon}
                        onClick={() => {
                          removeAllOfItemFromCart(id)
                          removeFromCartEvent()
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )
        })}
        {items.length === 0 ? (
          <Grid item xs={12}>
            <Typography variation="body2" align="center">
              Your cart is empty
            </Typography>
          </Grid>
        ) : (
          <>
            <Grid item md={3} xs={12} className={classes.cartDetails}>
              <Grid container direction="row">
                <Grid item xs={8} s={10}>
                  <Input
                    onChange={this.onChangeCouponCode}
                    type="text"
                    placeholder="Enter promo code"
                    value={couponCode}
                  />
                </Grid>
                <Grid item xs={4} s={2}>
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    fullWidth
                    style={{ height: '100%' }}
                    onClick={this.onApplyCouponCode}
                  >
                    Apply
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item md={9} xs={12} className={classes.cartDetails}>
              <Typography variant="body2" align="right">
                Cost: ${calculateDollars(subtotal)}
              </Typography>
              <Typography variant="body2" align="right">
                Shipping: ${calculateDollars(PRICING.SHIPPING)}
              </Typography>
              <Typography variant="body2" align="right">
                Subtotal: {`$${calculateDollars(subtotal + PRICING.SHIPPING)}`}
              </Typography>
              {this.renderCouponAndCombos()}
            </Grid>
          </>
        )}
        <Grid item xs={12} className={classes.buttons}>
          <Grid container spacing={8} justify="center">
            {items.length > 0 ? (
              <>
                <Grid item xs={12} md={2}>
                  <Link to="/">
                    <Button
                      size="small"
                      variant="contained"
                      color="secondary"
                      fullWidth
                    >
                      Keep Shopping
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={12} md={2}>
                  <Link to="/checkout">
                    <Button
                      size="small"
                      variant={items.length === 0 ? 'disabled' : 'contained'}
                      color="primary"
                      fullWidth
                    >
                      Checkout
                    </Button>
                  </Link>
                </Grid>
              </>
            ) : (
              <Grid item xs={12} md={8}>
                <Link to="/">
                  <Button
                    size="small"
                    variant="contained"
                    color="secondary"
                    fullWidth
                  >
                    Keep Shopping
                  </Button>
                </Link>
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    )
  }
}

const mapStateToProps = state => ({
  subtotal: getSubtotalForItems(state),
  items: getItems(state),
  coupon: getActiveCoupon(state),
  hasValidHisAndHersCombo: getHasValidHisAndHersCombo(state),
  hasValidThing1And2Combo: getHasValidThing1And2Combo(state),
})

const mapDispatchToProps = {
  removeAllOfItemFromCart,
  getCoupon,
  clearActiveCoupon,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(Cart))
