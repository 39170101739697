export const addItemToCart = (id, productId, item) => ({
  type: 'ADD_ITEM_TO_CART',
  id,
  productId,
  item,
})

export const setQuantityOfItem = (id, quantity) => ({
  type: 'SET_QUANTITY_OF_ITEM',
  id,
  quantity,
})

export const removeAllOfItemFromCart = id => ({
  type: 'REMOVE_ALL_OF_ITEM_FROM_CART',
  id,
})

export const createOrder = () => ({
  type: 'CREATE_ORDER',
})

export const updateOrder = () => ({
  type: 'UPDATE_ORDER',
})

export const createPayment = stripeToken => ({
  type: 'CREATE_PAYMENT',
  stripeToken,
})

export const clearActiveCoupon = () => ({
  type: 'CLEAR_COUPON',
})

export const getCoupon = couponId => ({
  type: 'GET_COUPON',
  couponId,
})

export const getOrder = (orderId, email) => ({
  type: 'GET_ORDER',
  orderId,
  email,
})

export const editShippingInformation = () => ({
  type: 'EDIT_SHIPPING_INFORMATION',
})

export const setShippingInformation = shippingInformation => ({
  type: 'SET_SHIPPING_INFORMATION',
  shippingInformation,
})
