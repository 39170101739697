import whiteShirt from '../static/white.png'
import blackShirt from '../static/black.png'
import blueShirt from '../static/blue.png'
import goldShirt from '../static/gold.png'
import grayShirt from '../static/gray.png'
import greenShirt from '../static/green.png'
import orangeShirt from '../static/orange.png'
import pinkShirt from '../static/pink.png'
import redShirt from '../static/red.png'
import yellowShirt from '../static/yellow.png'
import {
  COLOR_WHITE,
  COLOR_BLACK,
  COLOR_BLUE,
  COLOR_GOLD,
  COLOR_GRAY,
  COLOR_GREEN,
  COLOR_ORANGE,
  COLOR_PINK,
  COLOR_RED,
  COLOR_YELLOW,
} from '../constants'

const marginHorizontal = 4.2
const marginVertical = 2.1
const size = 18

export const dpr = window.devicePixelRatio || 1

const getMarginDimensions = () => [marginHorizontal, marginVertical]

const getTextSize = () => size

const textHasDecender = text => /[gjpqy]/.test(text)
const textHasAscender = text => /[A-Zbdfhkl]/.test(text)

export const getTextHeight = text => {
  const hasDescender = textHasDecender(text)
  const hasAscender = textHasAscender(text)
  // console.log(hasDescender, hasAscender)

  if (!hasAscender && !hasDescender) {
    // only normal lowercase
    return getTextSize() * (1 - 0.3 - 0.25)
  } else if (!hasAscender) {
    return getTextSize() * (1 - 0.3)
  } else if (!hasDescender) {
    return getTextSize() * (1 - 0.25)
  } else {
    return getTextSize()
  }
}

export const getBogoDimensions = (ctx, text) => {
  ctx.font = `italic 600 ${getTextSize()}px futura-pt`
  const { width } = ctx.measureText(text)
  const [marginX, marginY] = getMarginDimensions()
  const bogoWidth = width + marginX * 2
  const bogoHeight = getTextHeight(text) + marginY * 2
  return [bogoWidth, bogoHeight]
}

export const renderBogo = (
  ctx,
  text,
  textColor,
  bgColor,
  startX = 0,
  startY = 0
) => {
  const textSize = getTextSize()
  const [bogoWidth, bogoHeight] = getBogoDimensions(ctx, text, textSize)
  // console.log(bogoWidth, bogoHeight)
  ctx.rect(startX, startY, bogoWidth, bogoHeight)
  ctx.fillStyle = bgColor
  ctx.fill()
  ctx.font = `italic 600 ${textSize}px futura-pt`
  ctx.fillStyle = textColor
  const [marginX, marginY] = getMarginDimensions()
  ctx.fillText(
    text,
    startX + marginX,
    textHasDecender(text)
      ? startY + getTextHeight(text) * 0.75 + marginY
      : startY + getTextHeight(text) + marginY
  )
}

export const teeShirt = color =>
  new Promise((resolve, reject) => {
    const tee = new Image()
    switch (color) {
      case COLOR_WHITE:
        tee.src = whiteShirt
        break
      case COLOR_BLACK:
        tee.src = blackShirt
        break
      case COLOR_BLUE:
        tee.src = blueShirt
        break
      case COLOR_GOLD:
        tee.src = goldShirt
        break
      case COLOR_GRAY:
        tee.src = grayShirt
        break
      case COLOR_GREEN:
        tee.src = greenShirt
        break
      case COLOR_ORANGE:
        tee.src = orangeShirt
        break
      case COLOR_PINK:
        tee.src = pinkShirt
        break
      case COLOR_RED:
        tee.src = redShirt
        break
      case COLOR_YELLOW:
      default:
        tee.src = yellowShirt
        break
    }
    tee.onload = () => resolve(tee)
  })

export const getTeeShirtDimensions = async shirtColor => {
  const tee = await teeShirt(shirtColor)
  return [tee.width, tee.height]
}

export const renderTee = async (ctx, shirtColor, startX = 0, startY = 0) => {
  const tee = await teeShirt(shirtColor)
  ctx.drawImage(tee, startX, startY)
}
